<template>
  <div class="analytic-accounts-table">
    <table class="table">
      <tr>
        <th>Saison</th>
        <th>Compte analytiques</th>
        <th>Compte général</th>
        <th>Groupe analytique</th>
        <th class="text-right">Montant</th>
      </tr>
      <tr v-for="item of listOfItems" :key="item.getKey()">
        <td>{{ item.schoolYear.name }}</td>
        <td>{{ item.analyticAccount.getLabel() }}</td>
        <td>{{ item.generalAccount.getFullLabel() }}</td>
        <td v-if="!grouped">
          <span v-if="item.analyticAccount.group">{{ item.analyticAccount.group.name }}</span>
          <span v-else>-</span>
        </td>
        <td v-else>
          <span v-if="item.analyticAccount.isGroup">Oui</span>
        </td>
        <td class="number">{{ item.amount | currency }}</td>
      </tr>
      <tr v-for="schoolYear of schoolYears()" :key="schoolYear.id">
        <th colspan="4">Total
          <span v-if="schoolYear.id">{{ schoolYear.name }}</span>
          <span v-else>Hors facture</span>
        </th>
        <th class="number">{{ schoolYearTotal(schoolYear) | currency }}</th>
      </tr>
      <tr>
        <th colspan="4">Total</th>
        <th class="number">{{ getTotal() | currency }}</th>
      </tr>
    </table>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>

import { makeAnalyticAccount } from '@/types/payments'
import { distinct } from '@/utils/arrays'
import { compareNumbers, compareStrings } from '@/utils/sorting'

export default {
  name: 'AnalyticAccountsTable',
  props: {
    items: Array,
    amounts: Object,
    grouped: Boolean,
  },
  data() {
    return {
    }
  },
  watch: {
    items: function() { },
    amounts: function() { },
    grouped: function() { },
  },
  computed: {
    listOfItems() {
      const groupsMap = new Map()
      const groupedItems = []
      if (this.grouped) {
        for (const item of this.items) {
          if (item.analyticAccount.group) {
            let groupIndex = -1
            const key = '' + item.analyticAccount.group.id + ':' + item.generalAccount.id + ':' + item.schoolYear.id
            if (groupsMap.has(key)) {
              groupIndex = groupsMap.get(key)
              groupedItems[groupIndex].amount += item.amount
            } else {
              const newItem = item.clone()
              newItem.analyticAccount = makeAnalyticAccount(
                { id: item.analyticAccount.group.id, name: item.analyticAccount.group.name, }
              )
              newItem.analyticAccount.isGroup = true
              groupsMap.set(key, groupedItems.length)
              groupedItems.push(newItem)
            }
          } else {
            groupedItems.push(item)
          }
        }
        return groupedItems.sort(
          (elt1, elt2) => {
            return compareStrings(
              elt1.analyticAccount.name.toLowerCase(),
              elt2.analyticAccount.name.toLowerCase()
            )
          }
        )
      } else {
        return this.items
      }
    },
  },
  created() {
  },
  methods: {
    getTotal() {
      let total = 0
      for (let item of this.items) {
        total += item.amount
      }
      return total
    },
    schoolYears() {
      let schoolYears = distinct(this.items.map(elt => elt.schoolYear))
      return schoolYears.sort((a, b) => compareNumbers(a.startYear, b.startYear))
    },
    schoolYearTotal(schoolYear) {
      let total = 0
      for (let item of this.items.filter(elt => elt.schoolYear.id === schoolYear.id)) {
        total += item.amount
      }
      return total
    },
  },
}
</script>
<style scoped lang="less">
.number {
  text-align: right;
}
</style>
