<template>
  <span class="change-deposit-modal" v-if="canView">
    <b-modal
      dialog-class="modal-md"
      :id="id"
      cancel-title="Annuler"
      @ok.prevent="onUpdateDeposit"
      ok-variant="primary"
      ok-title="Enregistrer"
    >
      <template v-slot:modal-title>
        <b><i class="fas fa-money-bill-alt"></i> Dépôt</b>
      </template>
      <div v-if="errorText" class="error-text">
        <i class="fa fa-error"></i> {{ errorText }}
      </div>
      <b-row>
        <b-col cols="12">
          <b-row>
            <b-col>
              <b-form-group
                id="number-group"
                label="Numéro"
                label-for="number"
              >
                <b-form-input id="number" v-model="depositNumber"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                id="comments-group"
                label="Commentaires"
                label-for="comments"
              >
                <b-form-input id="comments" v-model="comments"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-modal>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import { mapActions } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import { makeDeposit } from '@/types/payments'
import { BackendApi } from '@/utils/http'

export default {
  name: 'change-deposit-modal',
  mixins: [BackendMixin],
  props: {
    id: {
      type: String,
      default: 'bv-change-deposit',
    },
    deposit: Object,
  },
  data() {
    return {
      depositNumber: '',
      comments: '',
      errorText: '',
    }
  },
  computed: {
    canView() {
      return this.deposit && this.hasPerm('payments.change_deposit')
    },
  },
  watch: {
    deposit: function(newValue, oldValue) {
      this.init()
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    init() {
      if (this.deposit) {
        this.depositNumber = this.deposit.number
        this.comments = this.deposit.comments
      } else {
        this.depositNumber = ''
        this.comments = ''
      }
    },
    async onUpdateDeposit() {
      this.errorText = ''
      try {
        const url = '/api/deposits/' + this.deposit.id + '/'
        const backendApi = new BackendApi('patch', url)
        let data = {
          comments: this.comments,
          number: this.depositNumber,
        }
        const resp = await backendApi.callApi(data)
        this.$bvModal.hide(this.id)
        this.$emit('update', { deposit: makeDeposit(resp.data), })
      } catch (err) {
        this.errorText = this.getErrorText(err)
      }
    },
  },
  mounted() {
    this.init()
  },
}
</script>
<style lang="less">
  .error-text {
    padding: 20px;
    color: #cc3700;
    background: #e0e0e0;
  }
</style>
